.wrapper {
  width: 100%;
  /* height: 600px; */
}

.image {
}

.parallaxItem {
  padding: 250px 100px;
  width: 100%;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
